<template>
  <nav class="p-3 pl-6 header">
    <div class="flex justify-start items-center text-white mr-6">
      <div>
        <h1 class="sm:text-xl text-lg">
          <b>Swiat Przesylek / Cainiao MAP</b>
        </h1>
      </div>
      <div>
        <button v-if="isLoggedIn" @click="handleLogout" class="hover:underline cursor-pointer font-bold pl-2 mr-3">Logout</button>
        <button @click="handleOpenPointsList" class="mr-4 rounded-lg py-1 px-2 text-black points-list-button">Points list</button>
        <span @click="handleOpenSettings" class="hover:underline cursor-pointer font-bold">Settings</span>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: "HeaderComponent",
  props:{
    isLoggedIn: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleOpenSettings() {
      this.$emit('open-settings');
    },
    handleLogout() {
      this.$emit('logout-modal');
    },
    handleOpenPointsList() {
      this.$emit('open-points-list');
    }
  }
}
</script>

<style scoped>
.header {
  position: relative;
  z-index: 2;
  background-color: #4F9AFB;
  -moz-box-shadow: 0px 5px 5px rgba(68, 68, 68, 0.4);
  -webkit-box-shadow: 0px 5px 5px rgba(68, 68, 68, 0.4);
  box-shadow: 0px 5px 5px rgba(68, 68, 68, 0.4);
  color: white;
}

.header > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.points-list-button {
  background-color: #f3f3f3;
  border: 1px solid red;
  transition: all .3s ease;
}

.points-list-button:hover {
  background-color: #474747;
  color: white;
}
</style>