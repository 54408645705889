import { axios as axiosInstance } from "@/services/axiosInstance";


export const getOwnPoints = async (data) => axiosInstance.get('/own', {params: {...data}});
export const getINPPoints = async (data) => axiosInstance.get('/static/inpost', {params: {...data}});
export const getRPoints = async (data) => axiosInstance.get('/static/ruch', {params: {...data}});
export const getKPoints = async (data) => axiosInstance.get('/static/k',  {params: {...data}});
export const getHalterPoints = async (data) => axiosInstance.get('/static/halter', {params: {...data}});
export const getMaxPoints = async (data) => axiosInstance.get('/static/max', {params: {...data}});
export const getTopPoints = async (data) => axiosInstance.get('/static/top', {params: {...data}});
export const getHotSpotsPoints = async (data) => axiosInstance.get('/static/hot-spots', {params: {...data}});
export const getInpTopPoints = async (data) => axiosInstance.get('/static/inp-top', {params: {...data}});
export const addNewPoint = async (data) => axiosInstance.post('/own', data);
export const editPoint = async (data) => axiosInstance.put('/own', data, {headers: {'Content-Type': 'multipart/form-data'}})
export const deletePoint = async (data) => axiosInstance.delete('/own', {data});
export const auth = async (data) => axiosInstance.post('/auth', data);
export const getTypes = async (data) => axiosInstance.get('/types', data);
export const getRestrictions = async (data) => axiosInstance.get('/auth/restriction', data);